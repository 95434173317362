import React from "react"
import styled from "styled-components"
import LocalMap from "../images/map.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LeafBorder from "../images/hand-painted-leaf-border-cut.png"

const Picture = styled.div`
  background-image: url("${LocalMap}");
  width: 100%;
  height: 400px;
  background-position: 10%;
  margin-bottom: 42px;
  @media (max-width: 768px) {
    background-position: 45%;
  }
`

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 260px;
  margin-bottom: 32px;
  background-size: 300px 30px;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const H4 = styled.h4`
  font-family: "Benton Sans";
  margin-bottom: 24px;
`

const Ul = styled.ul`
  font-family: "Benton Sans";
`

class TransportPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Location & Transport | Sara & Tim" />
        <H1>Location & Transport</H1>
        <LeafBorderPicture />
        <Picture></Picture>
        <P>
          Upwaltham Barns is set in the South Downs National Park next to the
          historic Goodwood Estate and is just half an hour from the South
          coast.
        </P>
        <H4>Public transport</H4>
        <P>
          There is no direct public transport to the venue; however there are a
          number of rail stations relatively close, from which you can take a
          taxi.
        </P>
        <Ul>
          <li>
            Pulborough (Southern Rail from London Victoria and Portsmouth) - 20
            minute drive
          </li>
          <li>Barnham (Southern Rail from Chichester) - 15 minute drive</li>
          <li>Amberley (Southern Rail from Portsmouth) - 20 minute drive</li>
          <li>Chichester (Southern Rail from Portsmouth) - 15 minute drive</li>
        </Ul>
        <H4>Taxis</H4>
        <P>
          It is essential that your transport to leave on Saturday night is
          organised in advance, as it can be very difficult to book a taxi on
          demand.
        </P>
        <Ul>
          <li>Barnham Station Taxis - 01243 551753, ellentaxi@btconnect.com</li>
          <li>
            Starline (Chichester) - 01243 531666, info@starlineminicabs.co.uk
          </li>
          <li>
            Central Cars (Chichester) - 01243 789432, centralcars@tiscali.co.uk
          </li>
          <li>A2B Travel (Chichester) - 01243 771515, atwobtravel@aol.com</li>
        </Ul>
        <H4>Driving</H4>
        <P>
          Upwaltham Barns is located between Petworth and Chichester on the A285
          in the village of Upwaltham, West Sussex.
        </P>
        <P>
          There are parking spaces for approximately 70 cars (which should
          easily be enough for all of our guests!), leaving the front of the
          barns clear for taxis and emergency vehicles. Cars may be left
          overnight but must be collected by 11.00 am the following day.
        </P>
      </Layout>
    )
  }
}

export default TransportPage
